<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#tab-1">ຍອດຕຸກເປົ່າ</v-tab>
      </v-tabs>
      <!-- <hr /> -->

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text>
              <EmptyBottle />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <!--
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>{{ text }}</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      -->
    </v-card>
  </div>
</template>

<script>
import EmptyBottle from "@views/Report/emptyBottle/tabbar/report_all_bottle_tab";

export default {
  components: {
    EmptyBottle,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    if (this.$route.query.tab == "report-all-bottle") {
      this.tab = "tab-1";
    }
  },
  watch: {
    tab: function (value) {
      if (value == "tab-1") {
        this.$router
          .push({ name: "Report-Empty-Bottle", query: { tab: "report-all-bottle" } })
          .catch(() => {});
      }
    },
  },
};
</script>

<style>
</style>
