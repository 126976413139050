<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຕຸກເປົ່າ</h2></v-col>
      <v-col class="al-end">
        <v-btn
            color="btn-primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row>
      <v-col>
        <v-menu
            v-model="start_menu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="start_date"
                label="ເລີ່ມວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="start_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
            v-model="end_menu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="end_date"
                label="ຫາວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="end_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex">
        <v-autocomplete
            v-model="selectedType"
            :items="Types"
            label="ເລືອກປະເພດ"
            item-text="name"
            item-value="value"
            dense
            outlined
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>

<!--            <v-col class="d-flex">-->
<!--              <v-select-->
<!--                  v-model="selectedProductType"-->
<!--                  :items="listProducts"-->
<!--                  label="ປະເພດຜະລິດຕະພັນ"-->
<!--                  item-text="name"-->
<!--                  item-value="id"-->
<!--                  dense-->
<!--                  outlined-->
<!--              ></v-select>-->
<!--            </v-col>-->

      <v-col class="d-flex">
        <v-select
            v-model="selectedStoreType"
            :items="store_Types"
            label="ເລືອກຂໍ້ມູນ"
            item-text="name"
            item-value="value"
            dense
            outlined
        ></v-select>
      </v-col>

      <v-col class="d-flex" v-if="selectedStoreType !=''">
        <v-autocomplete
            v-model="selectedSourceId"
            :items="dataSource"
            label="ຕົວເລືອ"
            item-text="name"
            item-value="id"
            dense
            outlined
        ></v-autocomplete>
      </v-col>
      <v-col class="al-end">
        <v-btn
            color="primary"
            @click="fetchData()"
        >Filter
        </v-btn
        >
      </v-col
      >
    </v-row>

    <v-row class="mb-1 mt-0">
      <v-col>
        <h3 class="success--text">
          ຕຸກທັງໝົດ: {{ Intl.NumberFormat().format(sumTotalImportBottle) }} ຕຸກ
        </h3>
      </v-col>
      <!--      <v-col>-->
      <!--        <h3 class="error&#45;&#45;text">-->
      <!--          ສົ່ງອອກທັງໝົດ:-->
      <!--          {{ Intl.NumberFormat().format(sumTotalExportBottle) }} ຕຸກ-->
      <!--        </h3>-->
      <!--      </v-col>-->
      <!--
      <v-col>
        <h3 class="primary--text">
          ຕຸກນ້ຳຢູ່ໂຮງງານ:
          {{ Intl.NumberFormat().format(sumTotalImportBottle) }}
        </h3>
      </v-col>
     
      <v-col>
        <h3 class="info--text">
          ຕຸກເປົ່າເຂົ້າໂຮງງານ:
          {{ Intl.NumberFormat().format(sumTotalImportBottle) }}
        </h3>
      </v-col>
      -->
    </v-row>
    <!-- <hr /> -->
    <v-data-table
        :headers="headers"
        :items="listEmptyBottles"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <template v-slot:item.import_export_store_type="{ item }">
        <div v-if="selectedType == 'import'">
          <div v-if="item.import_store_type">{{ item.import_store_type.license_plate }}</div>
          <div v-if="item.import_store_type">
            {{ item.import_store_type.name }}
          </div>
        </div>
        <div v-if="selectedType == 'export'">
          <div v-if="item.export_store_type">{{ item.export_store_type.license_plate }}</div>
        </div>
      </template> <template v-slot:item.bottle_type="{ item }">
        <div v-if="selectedType == 'import'">
          {{ item.import_bottle_type }}
        </div>
        <div v-if="selectedType == 'export'">
          {{ item.export_bottle_type }}
        </div>
      </template>
      <template v-slot:item.store="{ item }">
        <div v-if="selectedStoreType == 'factory'">
          <div v-if="item.import_store_type">
            {{ item.import_store_type.name }}
        </div>
        </div>
        <div v-if="selectedStoreType == 'warehouse'">
          <div v-if="item.import_store_type">
            {{ item.import_store_type.name }}
          </div>
        </div>
        <div v-if="selectedStoreType == 'branch'">
          <div v-if="item.import_store_type">
            {{ item.import_store_type.name }}
          </div>
        </div>
        <div v-if="selectedStoreType == 'driver'">
          <div v-if="item.import_store_type">
            {{ item.import_store_type.name }}
          </div>
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <div>
          {{ item.created_at | formatDate }}
        </div>
      </template>
    </v-data-table>
    <br/>
    <template>
      <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchData()"
      ></Pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      start_menu: false,
      end_menu: false,

      loading: false,
      exportLoading: false,
      TableLoading: false,
      listEmptyBottles: [],

      selectedFactory: "",
      factories: [],
      selectedProductType: 1,
      listProducts: [],

      selectedStoreType: "factory",
      store_Types: [
        // {
        //   name: "ທັງໝົດ",
        //   value: "all",
        // },
        {
          name: "ຍອດຕາມໂຮງງານ",
          value: "factory",
        },
        {
          name: "ຍອດຕາມສາງໂຮງງານ",
          value: "warehouse",
        },
        {
          name: "ຍອດຕາມສາຂາ",
          value: "branch",
        },
        {
          name: "ຍອດຕາມລົດຂົນສົ່ງ",
          value: "driver",
        }
      ],
      selectedSourceId: "",
      dataSource: [],

      //Sum Variable
      sumTotalExportBottle: 0,
      sumTotalImportBottle: 0,

      //Pagination
      offset: 12,
      pagination: {},
      per_page: 25,

      //Payment Type
      selectedType: "import",
      Types: [
        {
          value: "import",
          name: "ຮັບເອົາ",
        },
        {
          value: "export",
          name: "ສົ່ງອອກ",
        },
      ],

      headers: [
        {
          text: "ຜະລິດຕະພັນ",
          align: "start",
          sortable: false,
          value: "bottle_type",
        }, {
          text: "ປະເພດສາງ",
          align: "start",
          sortable: false,
          value: "store_type",
        },
        {
          text: "ຈຳນວນ",
          align: "start",
          sortable: false,
          value: "amount",
        },
        {text: "ຜູ້ຮັບຜິດຊອບ", value: "import_export_store_type", sortable: false},
        {text: "User", value: "driver_user.name", sortable: false},
        {text: "ປະເພດ", value: "type", sortable: false},
        {text: "ໂຮງງານ", value: "factory.name", sortable: false},
        {text: "ວັນທີ", value: "created_at"},
      ],
    };
  },
  metaInfo() {
    return {
      title: `ລາຍງານຍອດຕຸກນ້ຳໂຮງງານ`,
    }
  },
  methods: {
    fetchFactory() {
      this.$admin.get('list-factories').then(res => {
        this.factories = res.data.data.data;
        this.selectedSourceId = res.data.data.data[0].id;
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    fetchProductType() {
      this.$admin.get('list-bottle-types').then(res => {
        setTimeout(() => {
          this.listProducts = res.data.bottle_types;
          this.TableLoading = false;
        }, 100);
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
   async fetchData() {
      // (this.pagination.current_page);
      this.TableLoading = true;
     await this.$axios
          .get("/admin/report-empty-bottle", {
            params: {
              startDate: this.start_date,
              endDate: this.end_date,
              type: this.selectedType,
              // type_id: this.selectedType,
              store_type: this.selectedStoreType,
              store_type_id: this.selectedSourceId,
              page: this.pagination.current_page,
              per_page: this.per_page,
              // factory_id: this.selectedFactory,
            }
          })
          .then((res) => {
            if (res.status == 200){
              console.log(res.data)
              this.sumTotalImportBottle = res.data.total_bottle;
               if(this.selectedStoreType == 'factory'){
                 this.listEmptyBottles = res.data.factory_empty_bottles.data;
                 this.pagination = res.data.factory_empty_bottles;
               } else if(this.selectedStoreType == 'warehouse'){
                 this.listEmptyBottles = res.data.ware_empty_bottles.data;
                 this.pagination = res.data.ware_empty_bottles;
               } else if (this.selectedStoreType == 'branch'){
                 this.listEmptyBottles = res.data.branch_empty_bottles.data;
                 this.pagination = res.data.branch_empty_bottles;
               }else if (this.selectedStoreType == 'driver') {
                 this.listEmptyBottles = res.data.driver_empty_bottles.data;
                 this.pagination = res.data.driver_empty_bottles;
               } else{
                 this.listEmptyBottles = [];
                 this.pagination = {};
               }

              this.start_menu = false;
              this.end_menu = false;
              this.TableLoading = false;
            }

          })
          .catch(() => {
            this.TableLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },

    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$axios
          .get(
              "admin/export-empty-bottle",
              {
                params: {
                  startDate: this.start_date,
                  endDate: this.end_date,
                  type: this.selectedType,
                  // type_id: this.selectedType,
                  store_type: this.selectedStoreType,
                  store_type_id: this.selectedSourceId,
                },
                responseType: "blob"
              },
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "ລາຍງານຍອດຕຸກນ້ຳ " + this.start_date + "_" + this.end_date + ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    listDataSources() {
      if (this.selectedStoreType == 'factory') {
        this.$admin.get('list-factories').then(res => {
          this.dataSource = res.data.data.data;
          this.dataSource.unshift(this.defaultValue);
        }).catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
        });
      } else if (this.selectedStoreType == 'branch') {
        this.$admin.get('list-branch').then(res => {
          this.dataSource = res.data.branches;
          this.dataSource.unshift(this.defaultValue);
        }).catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
        });
      } else if (this.selectedStoreType == 'driver') {
        let originalData = [];
        this.$axios
            .get("list-water-truck")
            .then((res) => {
              originalData = res.data.trucks;
              const newData = originalData.map(item => {
                const newItem = {...item, name: item.license_plate};
                delete newItem.license_plate; // Remove the old key
                return newItem;
              });
              this.dataSource = newData;
              this.dataSource.unshift(this.defaultValue);
            })
            .catch(() => {
              this.$store.commit("Toast_State", this.toast_error);
            });
      } else if (this.selectedStoreType == 'warehouse') {
        this.$axios.get('list-factory-warehouses').then(res => {
          this.dataSource = res.data.data;
          this.dataSource.unshift(this.defaultValue);
        }).catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
        });
      }
    },
  },
  watch: {
    selectedStoreType: function () {
      this.selectedSourceId = "";
      this.listDataSources();
    }, selectedType: function () {
      this.fetchData();
    },
  },
  created() {
    // this.fetchFactory();
    this.listDataSources();
    this.fetchProductType();
    // this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}

.invoice {
  font-size: 16px;
  color: #000000;
}

.primary-color {
  color: #00c1d2;
}
</style>
